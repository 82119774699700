<script>
import Sidebar from '@/components/molecules/Sidebar';
import SidebarMixin from '@/components/molecules/Sidebar/mixins/SidebarMixin';
import TabletTopBar from '@/components/molecules/TabletTopBar';
import { SearchBar } from '~/features/search';

const layoutHeaders = {
  overview: defineAsyncComponent(() =>
    import('~/features/overview/components/Heading'),
  ),
};

export default defineNuxtComponent({
  components: { Sidebar, TabletTopBar, SearchBar },
  mixins: [SidebarMixin],
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {},
    },
    navigation: {
      type: Array,
      required: true,
      default: () => [],
    },
    locations: {
      type: Array,
      required: true,
      default: () => [],
    },
    checkedLocation: {
      type: Number,
      required: true,
      default: 0,
    },
    currentModule: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    bothSidebarsCollapsed() {
      return this.isSidebarCollapsed && this.isSecondSidebarCollapsed;
    },
    layoutHeader() {
      return layoutHeaders[this.currentModule];
    },
  },
  methods: {
    onSecondNavigationLevelElementClick() {
      this.setSidebarIsCollapsed(true);
      this.setSecondSidebarIsCollapsed(true);
    },
    onNavigationItemClick() {
      if (this.$refs.sidebar.selectedNavigationItem.children?.length)
        this.setSidebarIsCollapsed(true);

      if (!this.$refs.sidebar.selectedNavigationItem.children?.length)
        this.setSecondSidebarIsCollapsed(true);
    },
    onLocationChanged(location) {
      this.$emit('onLocationChanged', location);
    },
    onClose() {
      this.setSidebarIsCollapsed(true);
      this.setSecondSidebarIsCollapsed(true);
    },
    onCreate() {
      this.$emit('onCreateNew');
    },
    onMenuItemClick() {
      this.setSidebarIsCollapsed(!this.isSidebarCollapsed);
      this.setSecondSidebarIsCollapsed(!this.isSecondSidebarCollapsed);

      if (this.isSidebarCollapsed)
        this.setSecondSidebarIsCollapsed(true);
    },
  },
});
</script>

<template>
  <div class="flex h-full flex-col">
    <TabletTopBar
      id="tabletTopBar"
      :sidebar-collapsed="isSidebarCollapsed"
      :secondary-sidebar-collapsed="isSecondSidebarCollapsed"
      @on-menu-item-click="onMenuItemClick"
      @on-close="onClose"
      @on-create="onCreate"
    />
    <div class="flex h-full">
      <Sidebar
        ref="sidebar"
        :user="user"
        :navigation="navigation"
        :locations="locations"
        :checked-location="checkedLocation"
        device-type="tablet"
        @on-location-changed="onLocationChanged"
        @on-navigation-item-click="onNavigationItemClick"
        @on-second-navigation-level-element-click="
          onSecondNavigationLevelElementClick
        "
      />
      <div
        class="flex w-full flex-col px-6 transition-all duration-200"
        :class="{
          'ml-80': !isSidebarCollapsed && isSecondSidebarCollapsed,
        }"
      >
        <div class="flex flex-row">
          <SearchBar
            label=""
            :placeholder="__('What are you looking for?')"
            :selected-location="checkedLocation"
            class="py-6"
          />
        </div>
        <KeepAlive :max="3">
          <div class="flex flex-1 flex-col overflow-y-auto">
            <component :is="layoutHeader" />

            <NuxtPage />
          </div>
        </KeepAlive>
      </div>
    </div>
  </div>
</template>
