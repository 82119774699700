<script>
import { mapState } from 'vuex';

import ColorUtilities from '~/utilities/ColorUtilities';

import Icon from '@/components/atoms/Icon';

export default defineNuxtComponent({
  name: 'TabletTopBar',
  components: { Icon },
  props: {
    sidebarCollapsed: {
      type: Boolean,
      default: true,
      required: true,
    },
    secondarySidebarCollapsed: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  computed: {
    ...mapState('ContextStore', {
      color: state => state.accentColor,
      darkMode: state => state.darkMode,
    }),
    whiteColor() {
      return ColorUtilities.WHITE;
    },
  },
  methods: {
    onMenuClick() {
      let action = 'onMenuItemClick';
      if (!this.sidebarCollapsed)
        action = 'onClose';

      this.$emit(action);
    },
    onArrowLeftClick() {
      this.$emit('onArrowLeftClick');
    },
    onClose() {
      this.$emit('onClose');
    },
    onCreate() {
      this.$emit('onCreate');
    },
  },
});
</script>

<template>
  <div
    class="z-1 flex h-20 w-full items-center bg-secondary p-6 dark:bg-dark-secondary"
  >
    <div class="relative">
      <div
        class="flex h-12 w-12 cursor-pointer items-center justify-center rounded bg-menu-item-background transition-opacity duration-200"
        @click="onMenuClick"
      >
        <div class="absolute">
          <trailblazer-icon
            name="menu"
            :color="whiteColor"
            :class="{
              'block': sidebarCollapsed,
              'negative-position absolute hidden': !sidebarCollapsed,
            }"
          />
        </div>
        <div class="cursor-pointer">
          <trailblazer-icon
            name="close"
            :color="whiteColor"
            :class="{
              'block': !sidebarCollapsed,
              'negative-position absolute hidden': sidebarCollapsed,
            }"
          />
        </div>
      </div>
    </div>

    <div class="flex w-full content-center items-center justify-center">
      <LogoBig class="text-white" />
    </div>

    <div
      class="ml-auto flex h-12 w-14 cursor-pointer items-center justify-center rounded bg-menu-item-background"
      @click="onCreate"
    >
      <Icon name="plusEnclosed" :dark-mode="true" :accent="color" />
    </div>
  </div>
</template>

<style scoped>
.centered-margin {
  margin-left: calc(50% - 80px);
}
.negative-position {
  top: -500px;
}
</style>
